<template>
    <div class="main">
        <div class="search">
            <span style="color: #fff9ff;font-size: 14px;font-weight: bold">종목</span>
        </div>

        <div class="data">
            <div style="width: 100%;border: 1px solid #cfcfd1;border-radius: 5px;padding: 20px;background-color: #fff9ff">
                <el-collapse v-model="activeNames" accordion>
                    <el-collapse-item :key="index" v-for="(type,index) in gameTypeList"
                                      :title="'▷ '+type.typeName"
                                      :name="index">
                        <table class="table100" style="margin: 20px 0">
                            <tr>
                                <th style="width: 25%">번호</th>
                                <th style="width: 25%">종목명</th>
                                <th style="width: 25%">상태</th>
                                <th style="width: 25%">정수기준치</th>
                            </tr>
                            <tr>
                                <td>{{type.id}}</td>
                                <td>{{type.typeName}}</td>
                                <td>
                                    <el-select size="mini" style="width: 90%" v-model="type.status"
                                               placeholder="상태">
                                        <el-option :value="managerConst.ENABLE" label="정상">정상
                                        </el-option>
                                        <el-option :value="managerConst.DISABLE"
                                                   label="사용정지">사용정지
                                        </el-option>
                                    </el-select>
                                </td>
                                <td>
                                    <el-select size="mini" style="width: 90%" v-model="type.point5able"
                                               placeholder="상태">
                                        <el-option :value="managerConst.ENABLE" label="등록">등록
                                        </el-option>
                                        <el-option :value="managerConst.DISABLE"
                                                   label="패스">패스
                                        </el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 25%">정열순위</th>
                                <th style="width: 25%">추가배당(승무패) </th>
                                <th style="width: 25%">추가배당(핸디캡)</th>
                                <th style="width: 25%">추가배당(오버언더)</th>

                            </tr>
                            <tr>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.sort"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.extraodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.extraodds2"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.extraodds3"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 25%">핸디최소배당(풀타임) </th>
                                <th style="width: 25%">오언최소배당(풀타임)</th>
                                <th style="width: 25%">핸디최소배당(하프타임)</th>
                                <th style="width: 25%">오언최소배당(하프타임)</th>
                            </tr>
                            <tr>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.fhlowodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.foulowodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.hhlowodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.houlowodds"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th style="width: 25%">승패 합배당 </th>
                                <th style="width: 25%">핸디캡 합배당</th>
                                <th style="width: 25%">오버언더 합배당 </th>
                                <th style="width: 25%"></th>
                            </tr>
                            <tr>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.psumodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.hsumodds"></el-input>
                                </td>
                                <td>
                                    <el-input size="mini" type="number" style="width: 80%" v-model="type.osumodds"></el-input>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <el-button size="mini" type="primary" style="width: 30%;margin: 10px 0" @click="updateGameType(type)">업데이트</el-button>
                                </td>
                            </tr>

                        </table>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {getGameTypeList, updateGameType} from "../../network/manager/gameTypeRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    export default {
        name: "ManagerGameType",
        mixins:[manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                activeNames: 0,
                gameTypeList:[]
            }
        },
        methods: {
            get(){
                this.$confirm('xxxx', '취소/적특 처리', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateGroup({}).then(res=>{

                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            getList(){
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getGameTypeList().then(res=>{
                    this.gameTypeList=res.data.data
                    loadingInstance.close()
                })
            },
            updateGameType(gt){
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateGameType(gt).then(res=>{
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 2000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                    loadingInstance.close()
                })
            }
        },
        created(){
            this.getList()
        },

    }
</script>

<style scoped>

</style>